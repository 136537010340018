import "./App.css";
import { useState, useRef, useEffect } from "react";
import Papa from "papaparse";
import QRCodeStyling from "qr-code-styling";
import chevron from "./icons8-chevron-down-60.png";
import logo from "./logo.svg";
import logo_Color from "./Logo_Color.svg";
import upload from "./upload.svg";
import XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

const generateQrCode = (data) => new QRCodeStyling({
  width: 200,
  height: 200,
  data,
  qrOptions: {
    byteModeStringEncoding: "UTF-8",
    mode: "Byte",
  },
  dotsOptions: {
    color: "black",
    type: "dots"
  },
  cornersSquareOptions: {
    type: "dot"
  },
  cornersDotOptions: {
    type: "dot"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  }
});

function App() {
  //State to store the values
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const drop = useRef(null);

  const changeHandler = (event) => {
    setLoading(true)

    let parseFile = event?.target?.files[0] ?? event;

    if ((/.xlsx/).test(parseFile?.name)) {
      try {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.SheetNames[0];
          const elements = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
          setValues(elements)
        };
        reader.readAsArrayBuffer(parseFile);
      } catch {

      } finally {
        setLoading(false)
      }

      return;
    }

    setLoading(false)
    // TODO csv?
    Papa.parse(parseFile, {
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        setValues(valuesArray);
      },
    });
  };

  const soloPrintDocument = (index) => {
    setLoading(true)

    const input = document.getElementById(`print_${index}`);
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 20, 20);
        pdf.save("download.pdf");
        setLoading(false)
      })
      .catch(() => setLoading(false));
  }

  const printDocument = async () => {
    setLoading(true)
    try {
      const pdf = new jsPDF();
      const promises = values.map((_, index) => {
        const input = document.getElementById(`print_${index}`);
        return html2canvas(input);
      });
      const all = await Promise.all(promises)

      all.forEach((canvas, index) => {
        if (canvas) {
          let y = index === 0 ? 0 : 640;
          const pageHeight = pdf.internal.pageSize.height;
          const imgData = canvas?.toDataURL('image/png');

          if (y >= pageHeight) {
            pdf.addPage();
            y = 0 // Restart height position
          }
          // pdf.text(10, 10, JSON.stringify(values[index]));

          pdf.addImage(imgData, 'JPEG', 20, 20);
        }
      })

      pdf.save("download.pdf");
    } catch {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    drop.current.addEventListener('dragover', handleDragOver);
    drop.current.addEventListener('drop', handleDrop);
  
    return () => {
      drop.current.removeEventListener('dragover', handleDragOver);
      drop.current.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    changeHandler(e.dataTransfer?.files?.[0])
  };

  return (
    <div className="main_wrap">
      <div onClick={!!values?.length ? printDocument : () => {}}>
        <img src={logo_Color} alt="" className="logo_company" />
      </div>
      {values?.length < 1 && (
        <div className="wrapper_drop_zone" ref={drop} >
          <input
            type="file"
            name="file"
            id="file"
            onChange={changeHandler}
            accept=".csv, .xlsx"
            className="drop_zone"
            style={{display: 'none'}}
            hidden
            draggable
          />
          <label htmlFor="file">
            <div
              className="drop_zone div_zone"
              onDragEnd={(e) => {console.log(e)}}
              draggable
            >
              <div className="title">
                Q:OS QR-code generator
              </div>
              <div className="sub_title">
                <img src={upload} style={{ marginRight: '10px' }} /> Выберете или перетащите файл
              </div>
              <div className="hint">
                файл должен быть в формате excel
              </div>
            </div>
          </label>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center", margin: '20px' }}>{loading ? '...loading' : ''}</div>
      {values?.length > 0 && (
        <ScrollSync>
          <div className="qr_palce_wrapper">
            <ScrollSyncPane>
              <div className="left">
                {values.map((value, index) => 
                  <QrCode key={index} data={value} index={index} />
                )}
              </div>
            </ScrollSyncPane>
            <ScrollSyncPane>
              <div className="rigth">
                {values?.map((value, index) => {
                  return (
                    <div className="item" key={index}>
                      <div><pre className="wrap">{Object.values(value).join(',')}</pre></div>
                      <div>
                        <button onClick={() => soloPrintDocument(index)} disabled={loading}>
                          Распечатать
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </ScrollSyncPane>
          </div>
        </ScrollSync>
      )}
      <div className="spacer" />
      <div className="footer" />
    </div>
  );
}

const QrCode = ({ data, index }) => {
  const ref = useRef(null);
  const qrVal = Object.values(data).join(',')
  const qrInstance = generateQrCode(qrVal);

  useEffect(() => {
    qrInstance.append(ref.current);
  }, []);

  return (
    <div className="wrapper" id={`print_${index}`}>
      <div>
        Подтвердите бронирование
      </div>
      <img className="chevron" src={chevron} alt="" />
      <div className="white-square">
        <div className="qr" ref={ref} />
      </div>
      <div className="place">
        место
      </div>
      <div className="number">
        {data?.WorkplaceName}
      </div>
      <img className="logo" src={logo} alt="" />
    </div>
  );
}

export default App;
